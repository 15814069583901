.Contato {
    background-color: rgba(230,230,230,0.7);
    padding: 30px;
    border-radius: 10px;
    img{
        margin-bottom: 40px;
    }
    .link-group {
        display: flex;
        flex-direction: row;
        padding: 0 50px;
	@media (max-width: 760px) {
	    flex-direction: column;
	    padding: 0;
	}
    }
    .links {
        display: flex;
        flex-direction: column;
        width: 50%;
	a {
	    color: #212529 ;
	}	
    }
}
