$primary: #7D7C7A;
$dark: #2c3440;

@font-face {
  font-family: 'birth_of_a_heroregular';
  src: local('birth_of_a_heroregular'), url('./fonts/BIRTH_OF_A_HERO.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'oswald_regular';
  src: local('oswald_regular'), url('./fonts/Oswald-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}



@import "../node_modules/bootstrap/scss/bootstrap";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  
    background: rgb(88,29,34);
    background: radial-gradient(circle, rgba(88,29,34,1) 30%, rgba(209,67,67,1) 76%);
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

h1,h2,h3,h4{
  font-family: "oswald_regular";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
