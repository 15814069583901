.Footer {
 text-align: center;
 font-size: 2rem;
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 height: 80px;
 background-color: #2c3440;
 margin-top: 50px;

 svg{
     margin: 0 10px;
     color: whitesmoke;
 }

}
