.Videos{
    background-color: rgba(230,230,230,0.7);
    padding: 30px;
    border-radius: 10px;
    
    p {
        margin-top: 30px;
	text-align: center;
    }
}
