.Discografia {
    background-color: rgba(230,230,230,0.7);
    padding: 30px;
    border-radius: 10px;
    .row {
        margin: 20px 0;
    }
    .thumbnail {
        margin-bottom: 20px;
    }
    ol {
        padding: 0;
        list-style-position: inside;
    }
    ul {
        list-style: none;
        padding: 0;
    }
    .info-header {
        display: flex;
        justify-content: space-between;
    }
}
